.scrollable-tabs-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%; /* Ensure it does not exceed the viewport width */
  width: 50%;
}

.tab-button-container {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 45rem;
  overflow: hidden;
  overflow-x: scroll;
}

.scroll-buttons {
  display: inline-block;
}

/* Optional: Improve the appearance of the scrollbar */
.scrollable-tabs-container::-webkit-scrollbar {
  height: 8px;
}

.scrollable-tabs-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollable-tabs-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
