// Custom.scss
// Option A: Include all of SGDS theme

// Include any default variable overrides here

@import "~@govtechsg/sgds/sass/sgds.scss";

$primary-color: #0494e3;
$primary-color-hover: #0366a6;

.btn-primary {
  background-color: $primary-color !important;
  color: #ffffff !important;
  border-color: $primary-color !important;
}

.btn-outline-primary {
  color: $primary-color !important;
  border-color: $primary-color !important;
}

.btn-primary:hover {
  background-color: $primary-color-hover !important;
  color: #ffffff !important;
  border-color: $primary-color-hover !important;
}

.btn-outline-primary:hover {
  background-color: $primary-color !important;
  color: #ffffff !important;
  border-color: $primary-color !important;
}

sgds-aside-area {
  min-height: calc(100vh - 128px);
}

sgds-template-grid {
  background: #fafafa;

  &.with-sidenav {
    display: grid;
    grid-template-columns: 15rem minmax(min-content, 1fr);

    &.with-toc {
      grid-template-columns: 15rem minmax(min-content, 1fr) 15rem;
    }

    &.without-toc {
      grid-template-columns: 15rem minmax(min-content, 1fr) 0rem;
    }

    // @include media-breakpoint-down(lg) {
    //   display: block;
    //   sgds-aside-area,
    //   aside {
    //     display: none;
    //   }
    // }
  }

  &.without-sidenav {
    display: grid;
    grid-template-columns: 0rem minmax(min-content, 1fr);

    &.with-toc {
      grid-template-columns: 0rem minmax(min-content, 1fr) 15rem;
    }

    &.without-toc {
      grid-template-columns: 0rem minmax(min-content, 1fr) 0rem;
    }

    @include media-breakpoint-down(lg) {
      display: block;
      sgds-aside-area,
      aside {
        display: none;
      }
    }
  }
}

.accordion .header-text button {
  color: $primary-color;
  font-weight: bold;
}

@media (min-width: 769px) {
  .col-5-custom {
    flex: 0 0 20%;
    max-width: 20%;
    padding: 15px;
  }
}

body {
  color: #50504f !important;
}

.bg-blue {
  background: $primary-color;
}

.bg-gray {
  background-color: #f4f5f4;
}

.text-blue {
  color: $primary-color;
}

.btn-clear:hover {
  background-color: transparent !important;
  border: none !important;
}

.btn-clear {
  background-color: transparent !important;
  border: none !important;
}

/*Top Nav*/
.custom-nav {
  color: #ffffff !important;
  border-bottom: none !important;
}

.custom-nav.selected {
  font-weight: bold;
  background-color: #037bbd;
}

/*Sidebar*/
.custom-link {
  color: #50504f !important;
  border-left: none !important;
}

.custom-link:not(.active):hover {
  font-weight: normal !important;
}

.custom-link.active {
  color: $primary-color !important;
  font-weight: bold;
  border-left: 3px solid $primary-color !important;
}

/*Drop*/
.drop {
  background-color: #eff0ee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 0;
}

.collapse-animation {
  transition: max-height 0.3s ease-out;
  // overflow: hidden;
  // animation: slideIn 0.3s forwards ease-out;
}

/*Custom table*/
.custom-table > tr > td > input {
  border-bottom: 1px solid #98a2b3;
  margin-bottom: 10px;
}

@import "app-verification";
@import "app-homes";

//Accordion
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230494e3%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
  transform: rotate(-180deg);
}

@media screen and (max-width: 768px) {
  .title-middle {
    display: none;
  }
  .table-responsive-lg tr {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-bottom: 3px solid #ccc;
    display: block;
  }
  /*  IE9 FIX   */
  .table-responsive-lg td {
    float: left;
    width: 100%;
  }
}

@keyframes slideIn {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}

//HIDE ARROWS
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none; /* Standard */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

td,
th {
  word-wrap: break-word;
}

.wrapper-swiper {
  z-index: 0;
}

.drop-down-container {
  position: relative;
  overflow: hidden;
}
.drop-down {
  position: absolute;
}
