body,
html {
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

button {
  outline: none;
  text-decoration: none !important;
}

.left-sidebar {
  display: block !important;
  width: 240px;
  position: relative;
}

.right-sidebar {
  width: 240px;
  position: relative;
}
.left-sidebar__chevron {
  position: absolute;
  right: -30px;
  width: 32px;
  height: 30px;
  background-color: #fff;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 0px 6px 0px rgba(208, 213, 221, 0.5019607843);
  z-index: 999;
  padding: 5px;
  cursor: pointer;
}
.right-sidebar__chevron {
  position: absolute;
  left: -30px;
  width: 32px;
  height: 30px;
  background-color: #fff;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -3px 0px 6px 0px rgba(208, 213, 221, 0.5019607843);
  z-index: 999;
  padding: 5px;
  cursor: pointer;
}
.p-relative {
  position: relative;
}
.right-sidebar {
  background-color: white;
  height: 100%;
  padding: 20px;
  box-shadow: -3px 0px 6px 0px rgba(208, 213, 221, 0.5019607843);
}

@media (max-width: 991px) {
  sgds-template-grid.without-sidenav {
    display: grid !important;
  }
  .right-sidebar__chevron {
    display: none;
  }
}

.with-sidenav.without-toc sgds-content-area,
.without-sidenav.with-toc sgds-content-area {
  width: calc(100vw - 260px) !important;
  max-width: unset;
}
.without-sidenav.without-toc sgds-content-area {
  width: calc(100vw - 20px) !important;
  max-width: unset;
}
.with-sidenav.with-toc sgds-content-area {
  width: calc(100vw - 500px) !important;
  max-width: unset;
}
.next-custom.swiper-button-disabled,
.prev-custom.swiper-button-disabled {
  opacity: 0.5;
}

.wrapper-swiper {
  position: relative;
  margin: 0 30px;
}

.prev-custom,
.next-custom {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
}

.prev-custom {
  left: -30px;
}

.next-custom {
  right: -30px;
}

.swiper-wrapper button {
  width: 100%;
  border-radius: 0;
  background-color: white !important;
  color: #50504f !important;
  border: 1px solid #9fa09f !important;
}
.swiper-wrapper button.active {
  background-color: #0494e3 !important;
  border: 1px solid #0494e3 !important;
  color: #fff !important;
}
.cursor-pointer {
  cursor: pointer;
}
.card-dropdown {
  position: absolute !important;
  top: 45px;
  z-index: 9999;
  width: 100%;
  padding: 10px 20px;
  right: 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  border-radius: 0.25rem;
  transition: 0.3s;
  opacity: 0;
}
.card-dropdown.show {
  opacity: 1;
  top: 58px;
}

.card-dropdown a {
  color: #000;
}

.datepicker-faas .react-datepicker-wrapper {
  width: 100%;
}

@media (max-width: 991px) {
  .card-dropdown {
    width: 250px;
  }
}

@media (max-width: 576px) {
  .navbar-brand img {
    width: 130px;
  }
}

.list-style-none {
  list-style: none;
}
